<template>
  <div>
    <div class="cases">

      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">项目案例</div>
          <div class="tit2"></div>
        </div>

      </div>
      <div class="caselist" v-html="con">

      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user";
export default {
  data() {
    return {
      con: "",
    };
  },
  created() {
    this.getcompany();
  },
  methods: {
    async getcompany() {
      let params = {
        barId: 18,
      };
      const data = await getbannerdetail(params);
      this.con = data.data.content;
      var address = "http://36.152.65.166:10008";
      // var imgReg = /<img.*?(?:>|\/>)/gi;
      //  var srcReg = /src=['"]?([^'"]*)['"]?/i;
      //  this.con.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, p) {
      //    console.log(p);
      //     return `<img src=' ${address+p}'/>`;
      //   })
      var reg = new RegExp("/dev-api");
      this.con = this.con.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        function (match, p) {
          return `<img src=' ${address + p.replace(reg, "")}'/>`;
        }
      );
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.cases {
  height: calc(100vh - 270px);
  .caselist {
    width: 95%;
    margin: 1rem auto;
    ::v-deep p {
      text-indent: 0.4rem;
      line-height: 0.4rem;
      font-size: 0.25rem;
    }
    ::v-deep img {
      display: flex;
      margin: 0 auto;
      width: 80%;
    }
  }
}
.product_title {
  width: 7.5rem;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    .tit1 {
      font-size: 0.3rem;
      font-weight: bold;
      margin-bottom: 0.13rem;
    }
    .tit2 {
      width: 0.6rem;
      height: 0.04rem;
      background-color: #0088ea;
    }
  }
}
</style>